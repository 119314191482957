import { FC } from "react"
import { PageProps } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import AboutMe from "components/about-me"

const IndexPage: FC<PageProps> = () => (
  <Layout>
    <Seo title="About Me" />
    <AboutMe />
  </Layout>
)

export default IndexPage
